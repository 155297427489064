import * as React from "react"

import { Layout } from "../components"
import Cards from "../blocks/cards"
import { Icon } from "reflexjs"
import Block from "../components/cta.js"
import { Link } from "gatsby"

export default function IndexPage() {
  return (
    <Layout>

      <div>
        

      <section py="4|6|12|20" bg="secondary">
      <div variant="container" >
        <div textAlign="center" >
          <p  variant="subheading">Medical College of Wisconsin</p>
                      <h2 mt="1" variant="heading.h1alt" lineHeight="1">City of Milwaukee Evictions

            </h2>

            <p  variant="text.lead" color="background" mt="2" m='4|6|8' >
            Interactive maps and data visualizations to assess the use of eviction in Milwaukee.
            </p>
            <a variant="button.secondary.md" > <Link to="/about">Learn More </Link> <Icon name="arrow-right" ml="2" size="4" />
          </a>
        </div>

      </div>
    </section>
      </div>
      <Cards
        subheading=""
        heading=""
        text=""
        cards={[
          {
            heading: "Eviction Metrics",
            text:
              "Aggregate City of Milwaukee eviction data and case outcomes. View yearly and monthly trends. See top evictors by lead plantiff.",
            image: {
              src: "",
              alt: "",
            },
            link: (
              <a
                display="inline-flex"
                alignItems="center"
                variant="text.link"
              >
               <Link to="/general_eviction">Visualizations</Link> <Icon name="arrow-right" size="4" ml="2" />
              </a>
            ),
          },
          {
            heading: "Maps",
            text:
              "Explore eviction trends and outcomes at the address or parcel level. Assess how often certain owners file evictions. View eviction filing and judgement rates.",
            image: {
              src: "/",
              alt: "",
            },
            link: (
              <a
                display="inline-flex"
                alignItems="center"
                variant="text.link"
              >
                <Link to="/sda">Visualizations</Link>  <Icon name="arrow-right" size="4" ml="2" />
              </a>
            ),
          },
          {
            heading: "Code Violations & Evictions",
            text:
              "Landlords have an obligation to keep properties up to code and are prohibited from evicting a tenant for reporting issues to a city inspector. Identify evictions filed within 90 days of a building code violations and parcels with open orders upon eviction filing. ",
            image: {
              src: "",
              alt: "",
            },
            link: (
              <a
                display="inline-flex"
                alignItems="center"
                variant="text.link"
                href="#"
              >
                <Link to="/ilpr">Visualizations</Link>  <Icon name="arrow-right" size="4" ml="2" />
              </a>
            ),
          },
        ]}
      />

    </Layout>
  )
}
